<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="activeName">
      <el-tab-pane label="站点设置" name="site"></el-tab-pane>
      <el-tab-pane label="关注我们" name="followus"></el-tab-pane>
      <el-tab-pane label="关于我们" name="about_me"></el-tab-pane>
      <el-tab-pane label="搜索热词" name="hot_keys"></el-tab-pane>
    </el-tabs>
    <Site v-if="activeName === 'site'"></Site>
    <follow-us v-if="activeName === 'followus'"></follow-us>
    <AboutMe v-if="activeName === 'about_me'"></AboutMe>
    <HotKeys v-if="activeName === 'hot_keys'"></HotKeys>
  </div>
</template>

<script>
import Site from './modules/site.vue'
import AboutMe from './modules/aboutMe.vue'
import FollowUs from './modules/followUs.vue'
import HotKeys from './modules/hotKeys.vue'

export default {
  name: 'List',
  components: {HotKeys, Site, AboutMe, FollowUs},
  data() {
    return {
      activeName: 'site'
    }
  },

  mounted() {
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
