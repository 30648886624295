<template>
  <div class="container">
    <template v-if="uploadConf.qiniu_upload">
      <el-upload
        class="avatar-uploader"
        :action="uploadConf.qiniu_upload"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :on-error="handleError"
        :before-upload="beforeAvatarUpload"
        :data="uploadConf"
      >
        <img style="width: 100px; height: 100px" v-if="url" :src="url" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        <div class="el-upload__tip" slot="tip">
          <slot name="tip"></slot>
        </div>
      </el-upload>
    </template>
  </div>
</template>

<script>
import { localget, localset } from '@/utils/localStorage'
import { getQiniuToken } from './api'
import { nanoid } from 'nanoid'
import { Loading } from 'element-ui'
export default {
  name: 'UploaderFile',
  data() {
    return {
      uploadType: '',
      uploadConf: {},
      loading: null
    }
  },

  props: {
    url: {
      type: String,
      default: ''
    }
  },

  mounted() {
    const config = localget('qiniuConfig')
    if (config) {
      this.uploadConf = config
    } else {
      getQiniuToken().then(res => {
        // this.uploadType = res.uploadType
        this.uploadConf = res
        localset('qiniuConfig', this.uploadConf, 1800)
      })
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      console.log('开始上传')
      this.loading = Loading.service({
        lock: true, //是否锁定屏幕的滚动
        spinner: 'el-icon-loading',
        text: '上传中', //loading下面的文字
        background: 'rgba(0, 0, 0, 0.0)', //loading的背景色
        target: 'body' //loading显示在容器
      })
      let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
      this.uploadConf.key = nanoid() + '.' + fileExtension

      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG/PNG 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
        return false
      }
    },
    handleAvatarSuccess(res, file) {
      if (this.loading) {
        this.loading.close()
      }
      let url = this.uploadConf.domain + '/' + res.key
      this.imgUrl = url
      this.$emit('update:url', url)
    },
    handleError(res) {
      if (this.loading) {
        this.loading.close()
      }
      this.$message.error({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container /deep/ {
  padding-bottom: 0 !important;
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;

    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
  }
  .el-upload__tip {
    line-height: 25px;
    margin-top: 0;
  }
}
</style>
