<template>
  <div>
    <el-row>
      <el-col :span="4" v-for="(item, index) in formData.config_data" :key="index">
        <el-card :body-style="{ padding: '20px', margin: '20px' }">
          <div @click="showEdit(index)" style="width: 100%; height: 100%; text-align: center">
            <p><img :src="item.qrcode_url" alt="" style="width: 100px; height: 100px;"></p>
            <p style="margin-top:10px;"> {{ item.title }}</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
        title="修改"
        :visible.sync="dialogVisible"
        :modal="false"
        width="30%"
        :before-close="handleClose">
      <el-form ref="form" label-width="80px" :model="modifyConfigData">
        <el-form-item label="icon" prop="二维码">
          <UploaderImg :url.sync="modifyConfigData.qrcode_url"></UploaderImg>
        </el-form-item>
        <el-form-item label="名称" prop="title">
          <el-input v-model="modifyConfigData.title"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="modify()">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {getSettingConfigApi, settingConfigApi} from "./api";
import UploaderImg from '@/components/qiniu-uploader/uploader-img.vue'

export default {
  name: 'advantage',
  components: {UploaderImg},
  data() {
    return {
      dialogVisible: false,
      formData: {
        key: 'follow_us',
        describe: '关注我们',
        config_data: []
      },
      modifyConfigData: {
        title: '',
        qrcode_url: '',
      }
    }
  },

  mounted() {
    this.getDetail()
  },

  methods: {
    add() {

    },
    modify() {
      this.formData.config_data[this.modifyConfigData.index] = this.modifyConfigData
      settingConfigApi(this.formData).then((res) => {
        this.getDetail()
        this.$message.success('更新成功')
        this.dialogVisible = false
      })
    },

    showEdit(index) {
      this.modifyConfigData = this.formData.config_data[index]
      this.modifyConfigData.index = index
      this.dialogVisible = true
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    save() {
      settingConfigApi(this.formData).then((res) => {
        this.getDetail()
        this.$message.success('更新成功')
      })
    },
    getDetail() {
      getSettingConfigApi(this.formData.key).then(res => {
        this.formData.config_data = res.config_data
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
