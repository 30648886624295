<template>
  <div class="wrapper">
    <el-form
      ref="form"
      :model="formData"
      label-width="120px"
    >
      <el-form-item label="搜索热词">
        <el-input type="textarea" v-model="formData.config_data" :rows="3" placeholder="用中文顿号、分割"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="primary"
          size="small"
          @click="save"
        >保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getSettingConfigApi, settingConfigApi } from './api'

export default {
  name: 'hotKeys',
  data () {
    return {
      formData: {
        key: 'hot_keys',
        config_data: []
      },
    }
  },

  mounted () {
    this.getDetail()
  },

  methods: {
    cancel () {
      this.getDetail()
    },
    async getDetail () {
      const res = await getSettingConfigApi(this.formData.key)
      if (res) {
        this.formData = res
      }
    },
    save () {
      settingConfigApi(this.formData).then(() => {
        this.$message.success('保存成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
