import { http } from '@/http/axios.js'
// 存储storage
export function getSettingConfigApi(key) {
  return http({
    url: '/api/manage/setting/getSetting',
    method: 'get',
    params: { key }
  })
}
export function settingConfigApi(data) {
  return http({
    url: '/api/manage/setting/setting',
    method: 'post',
    data
  })
}
// 站点site
export function getSiteDetailAPI() {
  return http({
    url: '/api/manage/site/detail',
    method: 'get'
  })
}
export function saveSiteAPI(data) {
  return http({
    url: '/api/manage/site/setting',
    method: 'post',
    data
  })
}
